<script setup lang="ts">

</script>

<template>
    <NuxtLayout name="empty" background-color="bg-brand-b text-white">
        <template #main>
            <div class="flex h-full items-center justify-center">
                <NuxtPage />
            </div>
        </template>
    </NuxtLayout>
</template>
